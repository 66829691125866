<template>
  <b-modal
      :id="component.modal_id"
      size="xl"
      centered
      title="Editar Item"
  >
    <div class="procedure-item-edit-form" v-if="value">
      <b-row>

        <b-col class="col-8">
          <b-form-group class="form-group">
            <label for="item-select">Procedimento/Item</label>
            <b-form-input
                id="item_id"
                :value="value.name"
                disabled
            />
          </b-form-group>
        </b-col>

        <b-col class="col-4">
          <b-form-group class="form-group">
            <label for="item-combo">Quantidade</label>
            <b-form-input
                id="quantity"
                placeholder="Quantidade"
                type="number"
                v-model="value.quantity"
                :class="{ 'red-border': parseFloat(value.quantity) < 1}"
            />
            <div v-if="parseFloat(value.quantity) < 1" class="custom-invalid-feedback">Por favor, informe uma quantidade válida</div>
          </b-form-group>
        </b-col>

      </b-row>
      <!-- forms edition -->
      <b-row>
        <b-col class="col-3">
          <b-form-group class="form-group">
            <label for="unit_price">Valor Unitário</label>
            <money
                class="form-control"
                id="unit_price"
                placeholder="Preço Unitário"
                v-model="value.unit_price"
                :class="{ 'red-border': parseFloat(value.unit_price) <= 0}"
            />
            <div v-if="parseFloat(value.unit_price) < 0.01" class="custom-invalid-feedback">Por favor, um valor mínimo válido</div>
          </b-form-group>
        </b-col>
        <!-- discount format -->
        <b-col class="col-3">
          <b-form-group class="form-group">
            <label for="item_discount_format">Formato Desc.</label>
            <multiselect
                class="procedure-item form-control"
                id="discount_format"
                v-model="value.discount_format"
                :showLabels="false"
                :showNoResults="false"
                :searchable="false"
                :allow-empty="false"
                track-by="value"
                label="name"
                placeholder="Selecionar..."
                :options="Array.from(discountFormats.values())"
            >
              <template #caret>
                <div class="chevron">
                  <ChevronDown />
                </div>
              </template>
              <template #singleLabel="props">
                <div>
                  {{props.option.name}}
                </div>
              </template>
              <template #noOptions>
                Nenhuma opção
              </template>
              <template #noResult>
                Nenhum resultado
              </template>
            </multiselect>
          </b-form-group>
        </b-col>
        <!-- discount -->
        <b-col class="col-2" v-if="isObject(value.discount_format)">
          <b-form-group class="form-group">
            <label for="item-unit-price">Desconto</label>
            <template v-if="value.discount_format.value !== discountFormats.get('currency').value">
              <b-form-input
                  v-model="value.discount"
                  class="form-control"
                  type="number"
                  maxlength="2"
                  id="item_discount"
                  placeholder="Selecionar Desconto"
                  :showLabels="true"
                  :class="{ 'red-border': parseFloat(value.discount) < 0}"
              />
              <div v-if="parseFloat(value.discount) < 0.00" class="custom-invalid-feedback">Por favor, digite um desconto válido</div>
            </template>
            <template v-else>
              <money
                  v-model="value.discount"
                  class="form-control"
                  id="unit_price"
                  placeholder="Preço Unitário"
                  :class="{ 'red-border': parseFloat(value.discount) < 0}"
              />
            </template>
            <div v-if="parseFloat(value.discount) < 0.00" class="custom-invalid-feedback">Por favor, digite um valor maior/igual a zero</div>
          </b-form-group>
        </b-col>
        <!-- budget final amount -->
        <b-col class="col-2">
          <b-form-group class="form-group">
            <label for="item_final_price">Valor</label>
            <money
                class="form-control"
                id="final_price"
                placeholder="Valor Proposta"
                :value="finalPrice"
                disabled
            />
          </b-form-group>
        </b-col>
      </b-row>
    </div>
    <template #modal-footer>
      <div class="wrapper-button">
        <b-button
            class=""
            variant="primary"
            size="xg"
            @click="save"
        >
          Salvar
        </b-button>
      </div>
    </template>
  </b-modal>
</template>
<script>

import itemMixin from "@/modules/SellingPanel/mixins/itemMixin"
import {validate} from "@/utils/formValidator"
import {discountFormats, itemValidations} from "@/modules/SellingPanel/config/item"
import {isObject} from "@/utils/dataHelper"

export default {
  name: "ItemEditModalForm",
  mixins:[itemMixin],
  components: {
    ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
  },
  props: {
    value: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      component: {
        modal_id: 'item_edit_modal_form'
      }
    }
  },

  mounted() {
    this.$root.$on('bv::modal::shown', (bvEvent, modalId) => {
      this.beforeEdit()
    })
    this.$root.$on('hidden', (bvEvent, modalId) => {
      this.afterEdit()
    })

  },

  methods: {
    isObject,
    beforeEdit() {
      this.value.discount_format = discountFormats.get(this.value.discount_format)

    },
    afterEdit() {
      this.value.discount_format = this.value.discount_format.value
    },

    save() {
      this.value.final_price = this.computeItemFinalPrice(this.value)
      this.afterEdit()
      const validItem = this.validateItem(this.value)
      if(validItem) {
        // evaluate if there is the need to add persistence
        this.$toast.success(`Item ${this.value.name} Editado.`)
        this.$bvModal.hide(this.component.modal_id)
      }
      else {
        this.beforeEdit()
      }
    },

    validateItem(data) {
      let r = null

      r = validate(itemValidations, data)

      if(r.length > 0) {
        this.$toast.error(r.join('\n'))
        return false
      }

      return true
    },
  }
}
</script>


<style scoped lang="scss">

</style>