import { discountFormats } from '@sp/config/item'
const itemMixin = {
    computed: {
        /**
         * @todo find a way to deduplicate final price computation
         * @returns {Number}
         */
        finalPrice () {
            let total = parseFloat(this.value.unit_price * this.value.quantity).toFixed(2)

            if(this.value.discount > 0.00) {

                if (this.value.discount_format.value === discountFormats.get('currency').value) {
                    total = parseFloat(total - this.value.discount).toFixed(2)
                } else {
                    total = parseFloat(total - ((total/100) * this.value.discount)).toFixed(2)
                }

            }

            return parseFloat(total)
        }
    },
    data() {
        return {
            discountFormats
        }
    },
    methods: {
        /**
         * Get the final amount of a budget item
         * @param {Object} item
         * @returns {Number}
         */
        computeItemFinalPrice  (item) {
            let total = parseFloat(item.unit_price * item.quantity).toFixed(2)

            if(item.discount > 0) {
                if (item.discount_format.value === discountFormats.get('currency').value) {
                    item.discount = item.discount > total ? 0 : item.discount
                    total = parseFloat(total - item.discount).toFixed(2)
                } else {
                    item.discount = item.discount > 100 ? 0 : item.discount
                    total = parseFloat(total - ((total/100) * item.discount)).toFixed(2)
                }
            }

            return parseFloat(total)
        }
    }
}

export default itemMixin